.section-work-with {
    background-color: $color-grey-light-1;
    padding: 5rem 0 10rem 0;

    @include respond(tab-port) {
        padding: 13rem 0;
    }

    .techologiesIcons {
        display: grid;
        grid-template-columns: repeat(6 , 1fr);
        grid-gap: 4.5rem;
        row-gap: 5rem;

        @include respond(tab-port) {
            grid-template-columns: repeat(4 , 1fr);
        }

        @include respond(phone) {
            grid-template-columns: repeat(2 , 1fr);
        }

        img {
            width: 100%;
        }
    }
}
.mainBox {
    min-height: 30rem;
    background: $color-white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    text-align: center;
}

.section-about {
    background-color: $color-grey-light-1;
    padding: 5rem 0;

    @include respond(tab-port) {
        padding: 8rem 0 8rem 0;
    }
}

.section-features {
    padding: 5rem 0 8rem 0 !important;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-light, 0.8),
        rgba($color-primary-dark, 0.8)),
    url(../../assets/img/nat-4.jpg);
    background-size: cover;
    margin-top: -10rem;

    .ourServices {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 5rem;

        @include respond(tab-land) {
            grid-template-columns: repeat(2, 1fr);
            row-gap: 5rem;
        }

        @include respond(phone) {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 5rem;
        }
    }

    @include respond(tab-port) {
        padding: 7rem 0 13rem 0 !important;
    }

}

.section-contactUs {
    padding: 5rem 0!important;
    background-image: linear-gradient(
                    to right bottom,
                    rgba($color-primary-light, 0.8),
                    rgba($color-primary-dark, 0.8)),
    url(../../assets/img/nat-4.jpg);
    background-size: cover;
    margin-top: -10rem;

    @include respond(tab-port) {
        padding: 10rem 0;
    }

    .contactUs {
        background-color: white;
        border-radius: 0.5rem;

        .contactUsCard {
            margin: 2.6rem 0rem;
        }
        .contactUsCardBorder{
            border-right: 1px solid #adadad;

            @include respond(tab-port) {
                border-right: 0px solid #adadad;
                border-bottom: 1px solid #adadad;
            }
        }
        @include respond(tab-land) {
            width: 90%;
            padding: 5rem 0rem !important;
        }
    }

}


.section-features-jobs {
    padding: 5rem 0 5rem 0;
    background-image: linear-gradient(
                    to right bottom,
                    rgba($color-primary-light, 0.8),
                    rgba($color-primary-dark, 0.8)),
    url(../../assets/img/nat-4.jpg);
    background-size: cover;
    margin-top: -10rem;

    @include respond(tab-port) {
        padding: 10rem 0;
    }

}

.section-tours {
    background-color: $color-grey-light-1;
    padding: 5rem 0 15rem 0;
    margin-top: -10rem;

    @include respond(tab-port) {
        padding: 20rem 0 10rem 0;
    }
}

.heading-jobs {
    color: #ffffff;
    font-size: 1.8rem;
    padding-bottom: 5rem;
}

.apply-btn {
    padding-top: 4rem;
}

.about-text {
    font-size: 1.6rem;
    opacity: 0.6;
}

.feature-box svg {
    font-size: 5.1rem;
    margin: 25px 0 25px;
    color: $color-primary;
}

.text-white {
    color: #fff !important;
}

.about-card{
    position: relative;
    background: #fff;
    min-height: 324px;
    margin: 0px 20px;
    width: 34rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @include respond(tab-port) {
        margin: 0px;
        width: 100%;
    }
    
    .card-header{
        align-items: center;
        margin: 40px 0 0 0;
        display: flex;
        flex-direction: column;
        svg {font-size: 60px; color:#7ACE88}
        h3{
            font-size: 2rem;
            font-weight: 600;
            margin: 20px;
        }
    }
    .card-body {
        p{
            margin: 0 36px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            font-size: 16px;
            text-align: center;

            @include respond(tab-port) {
                margin: 0px 12px;
            }

        }
    }
    .card-body {
        margin-bottom: 20px;
    }

}
.card-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.display-brands {
    display: flex;
    justify-content: center;
    margin: 25px 0 0 0;
    button {
        width: 37px;
        height: 34px;
        border-radius: 50%;
        background: #3B5998;
        color: #fff;
        border: 0;
        svg{font-size: 20px;}
        margin: 5px;
        cursor: pointer;
    }
    button:focus {
        outline: 0;
    }
}
.contact-form {
    height: 525px;
    h3{
        font-size: 3.5rem;
        font-weight: 300;
    }
    form {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
    .row{width: 100%;margin: 2rem 0;}
    .form-group{
        input,textarea {
            width: 100%;
            padding: 15px 15px 15px 0px;
            border: 0;
            border-bottom: solid gray 0.5px;

        }
    }
    textarea:focus, input:focus{
        outline: none;
        border-bottom: solid #2F9A7A 1.5px;
    }

}
.display-image {
    img {
        height: 30rem;
        border-radius: 10px;
    }
}