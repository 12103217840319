.header {
    height: 100vh;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-light, 0.8),
        rgba($color-primary-dark, 0.8)),
    url(../../assets/img/header.jpg);
    background-size: cover;
    background-position: top;
    position: relative;
    

    @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
            only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
            only screen and (min-width: 125em) {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-primary-light, 0.8),
            rgba($color-primary-dark, 0.8)),
        url(../../assets/img/header.jpg);
    }


    &__logo-box {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__logo {
        width: 18rem;
    }
    
    &__text-box {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}