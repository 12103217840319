body {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    /*font-size: 16px;*/
    line-height: 1.7;
    color: $color-grey-dark;
}

.heading-primary {
    color: $color-white;
    text-transform: uppercase;

    backface-visibility: hidden;

    &--main {
        display: block;
        font-size: 6rem;
        font-weight: 400;
        letter-spacing: 3.5rem;
    
        animation-name: moveInLeft;
        animation-duration: 1s;
        animation-timing-function: ease-out;
        
        @include respond(phone) {
            letter-spacing: 1rem;
            font-family: 5rem;
        }

         @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
            only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
            only screen and (min-width: 125em) {
        font-size: 5rem;
        margin-left: 3rem;
    }

        /*
        animation-delay: 3s;
        animation-iteration-count: 3;
        */
    }
    
    &--sub {
        display: block;
        font-size: 1.5rem;
        font-weight: 700;
        letter-spacing: 1.10rem;
        animation: moveInRight 1s ease-out;

        @include respond(phone) {
            letter-spacing: .4rem;
        }
    }
}

.heading-secondary {
    font-size: 3.5rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(to right, $color-primary-light, $color-primary-dark);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: .2rem;
    transition: all .2s;
    animation: moveInTop 2s ease-out;
    


    @include respond(tab-port) {
        font-size: 3rem;
    }

    @include respond(phone) {
        font-size: 2.5rem;
    }

    // &:hover {
    //     animation: moveInRight 4s ease-out;
    //     transition: all .2s;
    // }
}

.heading-tertiary {
    font-size: $default-font-size;
    font-weight: 700;
    text-transform: uppercase;
}

.paragraph {
    font-size: $default-font-size;
    padding: 2rem 0rem;
    text-align: justify;
    text-align-last: center;

    &:not(:last-child) {
        margin-bottom: 3rem;
    }
}